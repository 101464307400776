<template>
  <div class="pageMain">
    <search-head  @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="角色名称">
          <el-select size="small" clearable v-model="searchForm.roleName" placeholder="请选择项目">
            <el-option v-for="item in roleListOption" :key="item.roleId" :label="item.roleName" :value="item.roleName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色编码">
          <el-input size="small" clearable v-model="searchForm.roleCode" placeholder="请输入公司代码"></el-input>
        </el-form-item>
        <el-form-item label="角色状态">
          <el-select size="small" clearable v-model="searchForm.roleState" placeholder="请选择企业名称">
            <el-option v-for="item in roleState" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables :data="tableData" :total="total"
                   noimport="true"
                   noexport="true"
                   isdel="true"
                   title="角色管理"
                   @selectionChange="selectionChange"
                   @handleCurrentChange="handleCurrentChange"
                   @handleSizeChange="handleSizeChange"
                   @tableDel="tableDel" @tableAdd="tableAdd">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="roleName"
          align="center"
          label="角色名称"
          width="180">
      </el-table-column>
      <el-table-column
          prop="roleCode"
          align="center"
          label="角色编码"
          width="180">
      </el-table-column>
      <el-table-column
          prop="isPreset"
          align="center"
          width="100"
          label="是否预置">
        <template slot-scope="scope">
          <span v-if="scope.row.isPreset == 0">是</span>
          <span v-if="scope.row.isPreset == 1">否</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="roleState"
          align="center"
          width="100"
          label="角色状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.roleState == 0" type="success">启用</el-tag>
          <el-tag v-if="scope.row.roleState == 1" type="warning">停用</el-tag>
          <el-tag v-if="scope.row.roleState == 2" type="danger">删除</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="roleNote"
          align="center"
          label="备注信息">
      </el-table-column>
      <el-table-column label="操作"  align="center" width="100">
        <template slot-scope="scope">
          <span class="el-icon-edit btn-icon" @click="editItem(scope.row)"></span>
          <span class="el-icon-delete btn-icon" @click="delItem(scope.row)"></span>
        </template>
      </el-table-column>
    </custom-tables>
    <custom-dialog :visible.sync="showDialog" width="500px" :title="dialogTitle" @close="colseDialog" @submit="editsubmit">
      <el-form :model="editForm" label-width="80px" class="edit-form" :rules="rules">
        <el-form-item label="角色名称" prop="roleName">
          <el-input size="small" v-model="editForm.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色编码" prop="roleCode">
          <el-input size="small" v-model="editForm.roleCode" placeholder="请输入角色编码"></el-input>
        </el-form-item>
        <el-form-item label="是否预置" prop="isPreset">
          <el-select class="block-select" size="small" v-model="editForm.isPreset" placeholder="请选择是否预置">
            <el-option v-for="item in presetState" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色状态" prop="roleState">
          <el-select class="block-select" size="small" v-model="editForm.roleState" placeholder="请选择角色状态">
            <el-option v-for="item in roleState" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注说明" prop="roleNote">
          <el-input size="small" v-model="editForm.roleNote" placeholder="请输入公司地址"></el-input>
        </el-form-item>
      </el-form>
    </custom-dialog>
  </div>
</template>

<script>
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import CustomDialog from "../../../components/common/CustomDialog";
import {roleState, presetState} from "../../../config/dataStatus";
import rights from "../../../api/modules/rights";

export default {
  name: "role",
  components: {CustomDialog, SearchHead, CustomTables},
  data(){
    return {
      dialogTitle:'新增公司',
      showDialog: false,
      total:0,
      tableData:[],
      searchForm:{
        currPage:1,
        pageSize:10
      },
      editForm:{
        roleName:'',
        isPreset: 1,
        roleCode: "",
        roleNote: "",
        roleState: 0
      },
      rules:{
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
        isPreset: [
          { required: true, message: '请选择是否预置', trigger: 'change' }
        ],
        roleState: [
          { required: true, message: '请选择角色状态', trigger: 'change' }
        ],
        roleCode: [
          { required: true, message: '请输入角色编码', trigger: 'blur' }
        ],
      },
      roleState: roleState,
      presetState: presetState,
      roleListOption: []
    }
  },
  mounted() {
    this.getRoleInfo()
    this.getAllRoleInfo()
  },
  methods:{
    getRoleInfo(){
      rights.getListByPage(this.searchForm).then(res =>{
        console.log(res)
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    getAllRoleInfo(){
      rights.getRoleList().then(res =>{
        console.log(res)
        this.roleListOption = res.data
      })
    },
    handleCurrentChange(pageIndex){
      this.searchForm.currPage = pageIndex
      this.getRoleInfo()
    },
    handleSizeChange(pageSize){
      this.searchForm.currPage = 1
      this.searchForm.pageSize = pageSize
      this.getRoleInfo()
    },
    headReset(){
      this.searchForm ={
        currPage:1,
        pageSize:10
      }
      this.getRoleInfo()
    },
    headSearch(){
      this.searchForm.currPage = 1
      this.searchForm.pageSize = 10
      console.log(this.searchForm)
      this.getRoleInfo()
    },
    tableDel(){

    },
    tableAdd(){
      this.dialogTitle = '新增角色信息'
      this.showDialog = true
    },
    selectionChange(val){
      console.log(val)
    },
    editItem(item){
      this.editForm = item
      this.dialogTitle = '编辑角色信息'
      this.showDialog = true
    },
    delItem(item){
      this.$confirm(`此操作将永久删除该公司, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rights.deleteRole(item.roleId).then(res =>{
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getInfo()
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    editsubmit(){
      if(this.editForm.roleId){
        // 编辑
        rights.editRole(this.editForm).then(res =>{
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.getRoleInfo()
          this.showDialog = false
        })
      }else {
        rights.addRole(this.editForm).then(res =>{
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.getRoleInfo()
          this.showDialog = false
        })
      }
    },
    colseDialog(){
      // 关闭编辑
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="less">
.edit-form{
  padding-right: 40px;
}


</style>